@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
* {
  font-family: "Roboto", sans-serif;
}
.header-md::after {
  background-image: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  color: #00205b;
}

ion-button.cc-button {
  text-transform: none;
  --padding-top: 8px;
  --padding-bottom: 8px;
  --padding-start: 30px;
  --padding-end: 30px;
  font-weight: 400;
  height: 40px;
}

ion-button.cc-tall-button {
  text-transform: none;
  --padding-top: 8px;
  --padding-bottom: 8px;
  height: 50px;
  font-weight: 500;
  font-size: 20px;
}

ion-button.cc-tall-button-16px {
  text-transform: none;
  --padding-top: 8px;
  --padding-bottom: 8px;
  height: 50px;
  font-weight: 500;
  font-size: 16px;
}

ion-button.cc-link-button {
  color: #555;
  text-transform: none;
  text-decoration: underline;
  --padding-top: 0px;
  --padding-bottom: 0px;
  --padding-start: 0px;
  --padding-end: 0px;
  font-weight: 500;
  font-size: 18px;
}

ion-select.cc-select {
  width: 100%;
  color: #00205b;
  border: 2px solid #00205b;
  border-radius: 5px;
  --highlight-height: 0;
  padding-top: 14px;
  padding-bottom: 15px;
  padding-right: 10px;
  --placeholder-color: #00205b;
  --placeholder-opacity: 0.5;
}
.alert-wrapper {
  /* @media (min-width:576px): {
        max-width: unset !important;
        width: 50% !important;
    } */
  max-width: unset !important;
  width: 70% !important;
}

.alert-checkbox-group::-webkit-scrollbar {
  width: 1em;
  display: block !important;
}
.alert-checkbox-group::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.alert-checkbox-group::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
.alert-radio-group::-webkit-scrollbar {
  width: 1em;
  display: block !important;
}
.alert-radio-group::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.alert-radio-group::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
.container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.container strong {
  font-size: 20px;
  line-height: 26px;
}

.container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

.container a {
  text-decoration: none;
}

/* TODO: why is this getting set?? */
.ion-page-invisible {
  opacity: 1;
}

.mt-5 {
  margin-top: 5px;
}
.mb-5 {
  margin-bottom: 5px;
}
.ml-5 {
  margin-left: 5px;
}
.mr-5 {
  margin-right: 5px;
}

.mt-16 {
  margin-top: 16px;
}
.mb-16 {
  margin-bottom: 16px;
}
.ml-16 {
  margin-left: 16px;
}
.mr-16 {
  margin-right: 16px;
}

.mt-24 {
  margin-top: 24px;
}
.mb-24 {
  margin-bottom: 24px;
}
.ml-24 {
  margin-left: 24px;
}
.mr-24 {
  margin-right: 24px;
}

.mt-48 {
  margin-top: 48px;
}
.mb-48 {
  margin-bottom: 48px;
}
.ml-48 {
  margin-left: 48px;
}
.mr-48 {
  margin-right: 48px;
}

.p-1 {
  padding: 1rem;
}

.disabled-pointer-events {
  pointer-events: none;
}

ion-segment-button {
  background: #f2f2f2;
  --indicator-color: #FF0000;
}

.segment-button-checked {
    background: #d3d3d3;
}

.toolbar-title {
  white-space: normal!important;
  word-break: break-word!important;
}

  /* TODO: why is this getting set?? */
  .ion-page-invisible {
      opacity: 1;
  }

  .required-field::after {
    content: "*";
  }
  
  .my-custom-interface .select-interface-option .alert-radio-label {
    --color-hover: #ec2168;
    color: #e40553;
  }
  .my-custom-interface
    .select-interface-option[aria-checked="true"]
    .alert-radio-label {
    color: #00a043;
    font-weight: bold;
  }